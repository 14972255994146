
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・MT】マニュアル車のメリット・デメリットを確認！
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            運転の状況に合わせてギアチェンジが必要なMT車は、自ら運転する楽しみを味わえるのが醍醐味です。中古車（MT）を購入しようかと迷っている方は、メリット・デメリットを理解して検討することをおすすめします。
            <div class="section-title">
              <span >MT車（国産車・外車）のメリット・デメリット</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      車を大きく分けると、マニュアル車（MT車）とオートマチック車（AT車）の2つがあります。これらの大きな違いは、ギアチェンジの操作を自分で行うかどうかです。MT車はギアチェンジする際にクラッチとブレーキを使用しながら手動で変えるのに対し、AT車はハンドルレバーの操作だけで自動的にギアが変わります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      こちらでは、MT車の購入を検討している方に向けて、MT車を購入するメリット・デメリット、中古のMT車を購入する際に気をつけるべきポイントをご紹介いたします。
                    </p>
                    <h3 class="heading-number" data-number="◆">MT車を購入するメリットとは？</h3>
                    <p class="u-m0 u-pb1em">
                      MT車は、自らギアチェンジを行うため運転する楽しさを味わえることです。ギアチェンジに慣れて、スムーズに操作できるようになれば、AT車よりも快適に運転できると感じる方も少なくありません。
                    </p>
                    <p class="u-m0 u-pb1em">
                      またMT車は、AT車のようにブレーキとアクセルの踏み間違えによる急発進などのトラブルの発生を防止できることも、大きなメリットです。クラッチペダルを離しながらアクセルを踏まなければ発進しないため、誤発進のリスクを最小限に抑えられます。
                    </p>
                    <h3 class="heading-number" data-number="◆">MT車を購入するメリットとは？</h3>
                    <p class="u-m0 u-pb1em">
                      MT車は運転操作が複雑であるため、AT車よりも運転が難しいと感じる方も少なくありません。また、坂道発進で後退する可能性がありますので、坂道発進が苦手な方は注意が必要です。後続車への追突リスクを抑えたい方はHSA（坂道発進補助装置）を搭載した車種を選んでみてください。
                    </p>
                    <p class="u-m0 u-pb1em">
                      MT車の性質上、先進安全装置との相性が悪いのもデメリットの1つです。また、MT車はAT車に比べて選べる車種が限定的なのもデメリットです。中古のMT車をお探しの方は、豊富な在庫を保有する販売店を探してみてください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/mt-car-merit-demerit.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">中古のMT車を選ぶ際の注意点</h3>
              <p class="u-m0 u-pb1em">
                中古のMT車を購入する際は、なぜMT車に乗りたいのか理由を明確にすることが大切です。乗りづらさで大きなストレスを感じないように、操作性の難しさなどを理解したうえで購入を検討してください。
              </p>
              <p class="u-m0 u-pb1em">
                また、購入前に販売店の試乗車やレンタカーを借りながら実際に運転することも大切なポイントです。試乗ができない場合には、販売店のスタッフにギアやクラッチの操作性について質問することをおすすめします。
              </p>
              <p class="u-m0 u-pb1em">
                さらに、購入前には、必ずミッションの状態を確認しましょう。抵抗が少なく、スムーズにギアを入れられるのか、そしてクラッチが滑っていないかチェックすることが大切です。クラッチが滑っていないか確認したい場合は、販売店の許可をとったうえで行ってください。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                SUBARUのWRXなど人気のMT車をお探しの方は、car shop MIXまでご相談ください。car shop MIXには、低走行の車種や25年規制が解除されるような価値の高い車種を集めるノウハウがございます。
              </p>
              <p class="u-m0 u-pb1em">
                国産車や外車問わず、幅広い車種のMT車を取り揃えていますので、ご希望の車種がございましたらお気軽にお問い合わせください。また、自動車板金・塗装・カークリーニング・オイル交換などの車両メンテナンスも受け付けています。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >中古のMT車の購入ならcar shop MIXにご相談を</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      MT車は、自ら操作できる楽しみがある、誤発進による事故発生のリスクを抑えられるなどのメリットがあります。一方で、運転操作の難しさや先進安全装置を搭載していない車種が多いこと、AT車に比べて車種の選択肢が少ないことなどはデメリットです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      また、中古のMT車の購入を検討しているのでしたら、運転操作のしやすさやミッションの状態を事前に確認しておきましょう。
                    </p>
                    <p class="u-m0 u-pb1em">
                    中古のMT車をお探しでしたらcar shop MIXにお任せください。人気の高級車をはじめ、一般的に市場に出回らないようなプレミアム車種や限定モデルの在庫も多数ございますので、お気に入りの一台をお探しいただけます。
                    </p>
                    <p class="u-m0 u-pb1em">
                      公式サイトの在庫状況をチェックすることで、ご希望の車種があるかをご確認いただけます。万が一、希望の車種がない場合でも、豊富なネットワークを活かしてご希望の車種をお探しいたします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      また、お客様が所有する自動車の買取にも対応可能です。中古車に関するご質問などがございましたら、お気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="中古車の三菱 ランサー エボリューションⅩ GSR" src="@/assets/img/column-maker-mt/used-car-mitsubishi-lancer-x.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'SUBARUのWRXなど人気の中古車(MT車)をお探しならcar shop MIXまでご相談ください！'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-05.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
